<template>

  <section class="invoice-preview-wrapper">
    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
        id="divToPrint"
      >
        <b-card
          no-body
          class="invoice-preview-card pdf-item"
          >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary">
                    {{ datas.name }}
                  </h3>
                </div>
                <p class="card-text mb-25">
                  <span style="font-weight: bold">Catégorie :</span> {{ datas.category }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">

                </h4>
                <div class="invoice-date-wrapper">
                  <p class="card-text mb-25">
                    <span style="font-weight: bold">Date :</span> {{ datas.date | moment("DD/MM/YYYY") }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">
          <b-card-body class="invoice-padding pb-0">

            <div class="justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <b-card-text>
                    <span style="font-weight: bold">Montant :</span> {{ datas.prixttc }} €
                </b-card-text>

                <b-card-text>
                    <span style="font-weight: bold">KM du véhicule :</span> {{ datas.km }}
                </b-card-text>

                <b-card-text>
                    <span style="font-weight: bold">Lieu de déplacement :</span> {{ datas.lieu }}
                </b-card-text>

               <b-card-text class="mt-2">
                    <img v-bind:src="datas.ticket" width="50%" heigth="auto" />
                </b-card-text>
            </div>

          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Imprimer
          </b-button>

          <!-- Button: Download -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="printDocument"
          >
            Télécharger en PDF
          </b-button>

           <!-- Button: Download -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="dark"
            class="mb-75"
            block
            @click="$router.go(-1)"
          >
            Retour
          </b-button>

        </b-card>
      </b-col>
    </b-row>
 </section>

</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BTableLite,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BLink,
    BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import PouchDB from 'pouchdb'
import Logo from '@core/layouts/components/Logo.vue'
import jsPDF from 'jspdf'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BCardBody,
        BTableLite,
        BCardText,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BInputGroup,
        BInputGroupPrepend,
        BForm,
        BButton,
        BLink,
        BFormSelect,
        Logo,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            datas: [],
        };
    },
    computed: {

    },
    methods: {
        setDatas(err, doc) {
            if (err) {
                console.log('err', err)
            }
            console.log('doc', doc)
            // console.log('doc', doc)
            this.datas = doc
        },
        printInvoice() {
            window.print()
        },
        printDocument() {

            var doc = new jsPDF({
                orientation: 'p',
                unit: 'mm',
                format: 'a4',
                putOnlyUsedFonts:false,
                floatPrecision: 16 // or "smart", default is 16
            });

            doc.html(document.getElementById('divToPrint'), {
                callback: function (doc) {
                    doc.save('test.pdf');
                    doc.output('dataurlnewwindow');
                },
                x: 10,
                y: 10,
                width: 250,
                windowWidth: 1170,
            });

        },
    },
    created(){
        this.localDB = new PouchDB('frais')
        this.remoteDB = new PouchDB('https://couchdb.placedusite.fr/frais')
        // this.localDB.sync(this.remoteDB, { live: true, retry: true })

        this.localDB.get(this.$route.params.id, this.setDatas)
    }
}
</script>


<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">

.invoice-content img {
    max-width: 100% !important;
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }


  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
